import React from 'react'
import { Link } from 'react-router-dom'
import { Link as LinkS } from "react-scroll";
import bgImg from "../asserts/images/instapay-Background-for-Web.jpg"
import softwareDevBlurb from '../asserts/images/software-dev-image.png'
import paymentSolBlurb from '../asserts/images/payment-solution-image.png'
import messagingBlurb from '../asserts/images/Blurb-messaging.png'
import businessIntelBlurb from '../asserts/images/Blurb-business-intelligence.png'
import resourceOutBlurb from '../asserts/images/Blurb-resource-outsourcing.png'



function HomeBanner() {
    return (
        <div className="HomeBanner">
            <img src={bgImg} alt="background" className="banner-background"/> 
            <div className="blurb-container">
                <div className="row container mx-auto">
                    <div className="col">
                        <Link>
                            <img className="heartbeat-1" src={softwareDevBlurb} alt="software dev" />
                        </Link>
                     </div>
                     <div className="col">
                        <Link>
                            <img className="heartbeat-2" src={paymentSolBlurb} alt="payment solutions" />
                        </Link>
                     </div>
                     <div className="col">
                        <Link>
                            <img className="heartbeat-3" src={messagingBlurb} alt="messaging" />
                        </Link>
                     </div>
                     <div className="col">
                        <Link>
                            <img className="heartbeat-4" src={businessIntelBlurb} alt="business intelligence" />
                        </Link>
                     </div>
                     <div className="col">
                        <Link>
                            <img className="heartbeat-5" src={resourceOutBlurb} alt="resource outsourcing" />
                        </Link>
                     </div>
                </div>

            </div>

            <div className="banner-text">
                <h1>The home of software solutions</h1>
                <LinkS className="cta" to="messaging" >Discover Tomorrow</LinkS>
            </div>
            
        </div>
    )
}

export default HomeBanner
