import React from "react";
import InstalipaBanner from "../components/InstalipaBanner";
import InstalipaBody from "../components/InstalipaBody";

function InstalipaPage(){

    return(
        <>
        <InstalipaBanner />
        <InstalipaBody />
        </>
        
    )
}

export default InstalipaPage;