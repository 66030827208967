import React from 'react'
import { Link } from 'react-router-dom'
import image from "../asserts/images/instapay-mobile.png"
import { IoLogoGooglePlaystore } from "react-icons/io5";

function CareersSection() {
    return (
        <div className="careers-section  px-2 py-5">
        <div className="row container">
            <div className="image col-sm-12 col-md-6 d-none d-md-block">
                    <img src={image} alt="careers" />
            </div>
            <div className="text d-flex flex-column justify-content-center col-sm-12 col-md-6">
                    <h3 className="mb-2">Instapay</h3>
                    <p>Instapay is a payment solution for businesses that want to bill clients and for individuals that seek to settle payments via all payment channels, local and global.</p>
                    <Link to="https://play.google.com/store/apps/details?id=zw.co.afrosoft.instapayapp"><IoLogoGooglePlaystore/> Google Play Store</Link>
            </div>
            
        </div>
        </div>
    )
}

export default CareersSection
