import React from 'react';
import { Link as LinkS } from 'react-scroll';
import { BiUpArrowCircle } from 'react-icons/bi';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="social-icons">
                    <a href="https://facebook.com/afrosoftzimbabwe/" target="_blank" rel="noopener noreferrer">
                        <FaFacebook size={30} />
                    </a>
                    <a href="https://twitter.com/afrosoftH" target="_blank" rel="noopener noreferrer">
                        <FaTwitter size={30} />
                    </a>
                    <a href="https://www.instagram.com/afrosoftholdings/" target="_blank" rel="noopener noreferrer">
                        <FaInstagram size={30} />
                    </a>
                    <a href="https://www.linkedin.com/company/1379582/" target="_blank" rel="noopener noreferrer">
                        <FaLinkedin size={30} />
                    </a>
                    <a href="https://youtube.com/channel/UCjDMqDPyzFkk6Nb03eSebIw" target="_blank" rel="noopener noreferrer">
                        <FaYoutube size={30} />
                    </a>
                </div>
                <p>Copyright &copy; 2024. All rights reserved</p>
                <LinkS className="cta" to="top" smooth={true} duration={1000}>
                    <BiUpArrowCircle className="upArrow" size={30} />
                </LinkS>
            </div>
        </footer>
    );
}

export default Footer;
