import React from "react";
import img1 from "../asserts/images/Landing pg_background.jpg"
import carouselImgOne from "../asserts/images/People.png"
import { Link as LinkS } from "react-scroll";

const SlideOne = () => {
  return (
    <>
      <img src={img1} className="d-block w-100 slide" alt="slider" />
      <div className="carousel-caption">
        <div className="row">
          <div className="d-none d-md-block col-xs-12 col-md-5 carousel-caption-image">
            <img src={carouselImgOne} alt="home of software solutions" />
          </div>
          <div className="col-md-7 carousel-caption-text">
            <h1>
              <span className="text-light top">The home of</span> <br />
              <span className="text-bold">Software</span> <br />
              <span className="text-light bottom">Solutions</span>
            </h1>
            <LinkS className="cta" to="messaging" smooth={true} duration={500}>Discover Tomorrow</LinkS>
          </div>
        </div>
      </div>
    </>
  );
};

export default SlideOne;
