import React, {useState, useEffect} from 'react'
import AwardsSection from '../components/AwardsSection'
import CareersSection from '../components/CareersSection'
import ContactSection from '../components/ContactSection'
import InstapaySection from '../components/InstapaySection'
import Subheading from '../components/Subheading'
import SystemsSection from '../components/SystemsSection'
import Carousel from "../components/carousel"
import LowerNavbar from '../components/LowerNavbar'
import MessagingSection from '../components/MessagingSection'
import Careers from '../components/careers'

function Home() {
    const [notMobileView, setNotMobileView] = useState(false)
    //screen size 
    const handleResize = () => {
        if (window.innerWidth >=720) {
            setNotMobileView(true)
        } else {
            setNotMobileView(false)
        }
    }

    // event listener
    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize)
    })
    return (
        <div>
            <Carousel />
            {/* <LowerNavbar/> */}
            <Subheading />
            <MessagingSection />
            <InstapaySection />
            <SystemsSection />
            <Careers />
            {notMobileView? <AwardsSection/>:""}
            <ContactSection />

        </div>
    )
}

export default Home
