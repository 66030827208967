import React from 'react';
import ValuesSection from './ValuesSection';
import VisionSection from './VisionSection';

function AboutBody() {
    return (
        <div className="container about-text">
            <h2 className="mt-5 mb-5 heading">Our Story</h2>
            <p>
                Afrosoft Holdings was established in April 1999 by a consortium of business people from the banking and allied industries as a research project specializing in software development, engineering, and distribution of software related solutions.
            </p>
            <p>
                In 2014 we re-branded ourselves to match our newfound purpose. This re-branding exercise involved redefining our core product lines and introducing a new corporate identity. With all these tools in our artillery, we stay on the pursuit of being a brand that impacts lives such as yours.
            </p>
            <p>
                Afrosoft Holdings has grown to serve the rest of Africa by opening offices in Zambia trading under the same name and in Tanzania trading under the name Breakthrough Technologies Africa.
            </p>
            <p>
                We can’t wait for you to be a part of our story!
            </p>

            <h2 className="mt-5 mb-5 heading">Our Experience</h2>
            <p>
                We have developed numerous software applications and systems for various enterprises and statutory bodies as well as fellow solution providers who required tailored systems for their own customers. We take pride in delivering customer centric solutions because they always come first in our line of work.
            </p>

            <h2 className="heading">Vision</h2>
            <p>
                The leading enterprise business solutions provider for Africa.
            </p>

            <h2 className="heading">Mission</h2>
            <p>
                We transform Africa through enterprise business solutions.
            </p>

            {/* <ValuesSection /> */}

            <h2 className="mt-5 mb-5 heading">Our Quality Policy</h2>
            <div id="quality-policy" className="quality-policy">
                <p>
                    Afrosoft Holdings is an empowerment vehicle developing and providing integrated, high-quality, world-class information technology solutions distributed globally through approved and certified agents. The company is dedicated to the quality policy that will ensure that its products and services fully meet the requirements of the customers at all times.
                </p>
                <p>
                    The scope of our solution packages covers the Commercial and Public sectors.
                </p>
                <h3>Compliance</h3>
                <p>
                    To achieve the above we comply with all statutory and regulatory requirements in order to offer outstanding services to our customers and other stakeholders.
                </p>
                <h3>Objectives</h3>
                <p>
                    Afrosoft undertakes to meet the following objectives:
                </p>
                <ul>
                    <li>To provide high quality, integrated, mission critical, information technology solutions that meet the client’s specific requirements.</li>
                    <li>To provide cost effective, quality products and timely service.</li>
                    <li>To maintain 100% retention of performing customers and suppliers.</li>
                    <li>To establish excellent communication with both internal and external customers.</li>
                    <li>To realize high return on investment through secure and assured growth.</li>
                    <li>To maintain competent staff through periodic training and development programs.</li>
                    <li>To recruit and retain only the best members of staff, offering market related remuneration to good performers.</li>
                </ul>
                <p>
                    These Quality objectives shall be reviewed for continuing suitability.
                </p>
                <p>
                    To achieve the above we have put in place a Quality Management System based on the ISO 9001 standards as a basis for continuously monitoring and improving our performance.
                </p>
                <p>
                    I therefore call for total commitment to Quality in all we touch as Team AFROSOFT. Our chosen “differential focus strategy” implies high value and quality service delivery to our clients. Total Quality Management System implementation is therefore not an option but our survival.
                </p>
                <p>
                    <strong>Signed:</strong> Afrosoft GCEO
                </p>
                <p>
                    <strong>Date:</strong> 21.06.2024
                </p>
                <p>
                    Join us in shaping the future of enterprise business solutions and take your career to new heights! Apply today.
                </p>
            </div>
        </div>
    );
}

export default AboutBody;
