import React from 'react'
import QualityBanner from '../components/QualityBanner'
import QualityBody from '../components/QualityBody'

const QualityPolicy = () => {
  return (
    <div>
        <QualityBanner />
        <QualityBody />
    </div>
  )
}

export default QualityPolicy
