import React from 'react'

function ContactBanner() {
    return (
        <div className="ContactBanner">
            <h1>Contact Us</h1>
        </div>
    )
}

export default ContactBanner
