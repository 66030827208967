import React from 'react'
import ContactBanner from '../components/ContactBanner'
import ContactBody from '../components/ContactBody'

function ContactUs() {
    return (
        <div>
            <ContactBanner />
            <ContactBody />
        </div>
    )
}

export default ContactUs
