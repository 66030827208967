import React from 'react'
import OurTeamBanner from '../components/OurTeamBanner'
import OurTeamBody from '../components/OurTeamBody'

function OutTeam() {
    return (
        <div>
            <OurTeamBanner />
            <OurTeamBody />
        </div>
    )
}

export default OutTeam
