import React from 'react'
import img from '../asserts/images/AfroSoft-Logo-in-White.png'

function Subheading() {
    return (
        <div className="Subheading d-flex flex-column justify-content-center align-items-center">
            <img src={img} alt="software - solutions - synegeries"  />
            <h1 className="mt-5">SOFTWARE FOR AFRICA</h1>     
        </div>
    )
}

export default Subheading