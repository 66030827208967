import React from 'react'

function CareersBanner() {
    return (
        <div className="careers-banner">
            <h1>Careers</h1>
        </div>
    )
}

export default CareersBanner