import React from 'react'
import {Link} from 'react-router-dom'
const Careers = () => {
    return (
        <div className="Careers">
            
            <div className="row container">
            <div className="col-md-6 left-text p-5">
                    <h2>Careers</h2>
                    <p>
                        Join our ever expanding team of professionals<br/> by applying for the post that suits<br/> your career, dreams and qualifications.
                    </p>
                    <Link className="cta mt-3" to="/careers">
                        View Posts
                    </Link>
                </div>
                
            </div>
        </div>
    )
}

export default Careers
