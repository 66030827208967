import React from 'react'
import { Link } from 'react-router-dom';
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { FaPlay } from "react-icons/fa";

function InstapaySection ()  {
    return (
        <div className="Instapay">
            <div className="row container">
                <div className="col-md-6 left-text p-5">
                    <h2>Instapay</h2>
                    <p>
                         Instapay is a payment solution for businesses that want to bill clients &amp; for individuals that seek to settle payments via all payment channels, local and global
                    </p>
                    <Link className="cta mt-3" to="/messaging-app" target="_blank">
                        <IoLogoGooglePlaystore/>
                        Google Play
                    </Link>
                    <div className="border-left"></div>
                    <Link className="biller" to="/register-as-biller" target="_blank">
                        <FaPlay /> Apply to be a Biller
                    </Link>
                </div>
                <div className="col-md-6 right-image"></div>

            </div>
        </div>
    )
}

export default InstapaySection