import React from 'react'
import AboutBanner from '../components/AboutBanner'
import AboutBody from '../components/AboutBody'

function About() {
    return (
        <div>
          <AboutBanner />
          <AboutBody />
        </div>
    )
}

export default About
