import React from 'react'

const QualityBanner = () => {
  return (
    <div className="QualityBanner">
        <h1>Afrosoft Holdings Quality Policy</h1>
        </div>
  )
}

export default QualityBanner