import React from 'react'

function OurWorkBanner() {
    return (
        <div className="OurWorkBanner">
            <h1>Our Work</h1>
        </div>
    )
}

export default OurWorkBanner
