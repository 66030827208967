import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';

export default function BulkSms() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
//https://drive.google.com/file/d/1bk5Ne9SDeN9R888tKbZWzG3CuQOx3fV8/view?usp=sharing
  return (
    <div>
      <Document
        file="somefile.pdf"
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <p>Page {pageNumber} of {numPages}</p>
    </div>
  );
}