import React, {useState, useEffect} from 'react'

import BottomNav from '../BottomNav'
import HomeBanner from '../HomeBanner'
import SlideOne from '../SlideOne'

const Carousel = () => {
    const [notMobileView, setNotMobileView] = useState(false)
    //screen size 
    const handleResize = () => {
        if (window.innerWidth >= 720) {
            setNotMobileView(true)
        } else {
            setNotMobileView(false)
        }
    }

    // event listener
    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize)
    })
    return (
        <>
        <div className= "main-banner-b" id="top">
            <div id="bannerCarousel" className="carousel slide" data-bs-ride="carousel">
            <div id="carousel-overlay"></div>
            <div className="carousel-indicators mb-5">
                <button type="button" data-bs-target="#bannerCarousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#bannerCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
            </div>
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <SlideOne />
                </div>
                <div className="carousel-item">
                    <HomeBanner />
                </div>
                
                {notMobileView? <BottomNav/>:""}
                
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#bannerCarousel" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#bannerCarousel" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
            </div>
        </div>
            
        </>
    )
}

export default Carousel
