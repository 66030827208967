import React from 'react'
import {Link} from 'react-router-dom'

function OurWorkBody() {
    const thumbnails = [
        {
            title: "Online Integrated Immigration System",
            subTitle: "This product offers e-government and enterprise solutions. We are the provider of The Zimbabwe Immigration System, The Parks & Wildlife Revenue Collection system.",
            image: require('../asserts/images/portfolio-thumb-1.jpg').default,
            cta : false
        },
        {
            title: "Messaging",
            subTitle: "Afrosoft is a mobile solutions provider for Mobile Network Operators across the African continent.",
            image: require('../asserts/images/portfolio-thumb-2.jpg').default,
            cta : false,
            ctaText : "Visit Messaging Website",
            link: "instapay"
        },
        {
            title: "Resource Outsource",
            subTitle: "Afrosoft is also in the business of outsourcing resources to clients that require assistance in developing software, testing, infrastructure, project management.",
            image: require('../asserts/images/portfolio-thumb-3.jpg').default,
            cta : false
        },
        {
            title: "Core Banking System",
            subTitle: "Our software provides product definition, transaction processing and all other bank operations for the different types of banks within the financial services sector.",
            image: require('../asserts/images/portfolio-thumb-4.jpg').default,
            cta : false
        },
        {
            title: "Investments System",
            subTitle: "Our investments system is made for financial institutions that intend to manage and grow their clients’ portfolios",
            image: require('../asserts/images/portfolio-thumb-5.jpg').default,
            cta : false
        },
        {
            title: "Loans Manager",
            subTitle: "Our loans manager is a world class software application which has automated key processes that must occur within a Microfinance institution which helps improve productivity & customer service.",
            image: require('../asserts/images/portfolio-thumb-6.jpg').default,
            cta : false
        },
        {
            title: "Agromall",
            subTitle: "Is an application that connects the farmers & inputs suppliers, backed up by appropriate policies and legislative frameworks",
            image: require('../asserts/images/portfolio-thumb-7.jpg').default,
            cta : true,
            ctaText : "Go To Website",
            link: 'agromall'
        },
        {
            title: "Instapay",
            subTitle: "Is the Afrosoft payments solutions which caters for businesses along with individual consumers.",
            image: require('../asserts/images/portfolio-thumb-8.jpg').default,
            cta : true,
            ctaText : "Go To Website",
            link: 'instapay'
        },
        {
            title: "Pensions Administration System",
            subTitle: "Our software automates the pensions administration processes within a pension fund institution improving customer service and productivity.",
            image: require('../asserts/images/portfolio-thumb-9.jpg').default,
            cta : false,
        },
        {
            title: "Business Intelligence and Analytics",
            subTitle: "A technology-driven process that uses reports, visuals, dashboards and information analysis to transform data into actionable intelligence helping organizations' strategic & tactical business decisions.",
            image: require('../asserts/images/portfolio-thumb-10.jpg').default,
            cta : false,
        },
        {
            title: "Health Insurance Manager",
            subTitle: "Is an end to end medical aid management software which is integrated with other key business functions.",
            image: require('../asserts/images/portfolio-thumb-11.jpg').default,
            cta : false,
        },
        {
            title: "Complaints Management System",
            subTitle: "Our system helps companies to address customer inquiries, deliver consistent support and manage complaints in a timely manner.",
            image: require('../asserts/images/portfolio-thumb-12.jpg').default,
            cta : false,
        },

    ] 

    return (
        <div className="Portfolio">
            <div className="top">
                <div className="container p-5 text-center">
                    <p>
                    Over the past two decades, our business has managed to create products for both the public and private sector in Zimbabwe and across the borders. Our software solutions are robust, secure, scalable and customisable to enable all types and sizes of institutions. 
                    </p>
                </div>
            </div>
            <div className="bottom">
                <div className="container">
                    <div className="row">
                    {
                       thumbnails.map(thumbnail => {
                           return (
                               <div className="col-md-6 thumbnail-container shadow-sm">
                                   <img src={thumbnail.image} alt="afrosoft portfolio" />
                                   <div className="overlay">
                                        <div className="text">
                                            <h3>{thumbnail.title}</h3>
                                            <p>{thumbnail.subTitle}</p>
                                            {thumbnail.cta ? <Link to={thumbnail.link}>{thumbnail.ctaText}</Link> : ''}
                                        </div>
                                    </div>
                               </div>
                           );
                       }) 
                    }
                    </div>
                </div>
            </div>
            <div className="container">
                    <p className="p-5 text-center">
                    Our team comprises of industry recognised experts contributing to the world’s most important software development issues. All our products and services are built using the following technologies.
                    </p>
            </div>
        </div>
    )
}

export default OurWorkBody
