import React from 'react'
import Image from '../asserts/images/404.svg'

function PageNotFound() {
    return (
        <div className="PageNotFound">
            <img src={Image} alt="PageNotFound" />
            <h1>Page Not Found</h1>
        </div>
    )
}

export default PageNotFound
