import React from 'react'
// import team from './team-data'

function OurTeamBody() {

    const team = [
        {
            name : "Sandra Usiku",
            image : require('../asserts/images/arty-events-9512.jpg').default
        },
        {
            name : "Nyasha Chimowa",
            image : require('../asserts/images/arty-events-9517.jpg').default
        },
        {
            name : "Tafadzwa Baloyi",
            image : require('../asserts/images/arty-events-9430.jpg').default
        },
        {
            name : "Ralph Nyoni",
            image : require('../asserts/images/arty-events-9567.jpg').default
        },
        {
            name : "Mitchel Severa",
            image : require('../asserts/images/arty-events-9463.jpg').default
        },
        {
            name : "Fungai Murerekwa",
            image : require('../asserts/images/arty-events-9456.jpg').default
        },
        {
            name : "Sean Gondo",
            image : require('../asserts/images/arty-events-9453.jpg').default
        },
        {
            name : "Allan Chimangah",
            image : require('../asserts/images/arty-events-9448.jpg').default
        },
        {
            name : "Stanley Tadzingwa",
            image : require('../asserts/images/arty-events-9447.jpg').default
        },
        {
            name : "Pamela Mudambo",
            image : require('../asserts/images/arty-events-9443.jpg').default
        },
        {
            name : "Webster Nhidza",
            image : require('../asserts/images/arty-events-9438.jpg').default
        },
        {
            name : "Ruth Moyo",
            image : require('../asserts/images/arty-events-9434.jpg').default
        },
        {
            name : "Mazvita Mahari",
            image : require('../asserts/images/arty-events-9423.jpg').default
        },
        {
            name : "Poetia Mushoriwa",
            image : require('../asserts/images/arty-events-9419.jpg').default
        },
        {
            name : "Hopewell Chaza",
            image : require('../asserts/images/arty-events-9416.jpg').default
        },
        {
            name : "Khulani Marava",
            image : require('../asserts/images/arty-events-9413.jpg').default,
        },
        {
            name : "Shahnaaz Ismail",
            image : require('../asserts/images/shannaz.jpg').default
        },
        {
            name : "Tendai Mushonga",
            image : require('../asserts/images/tendai-mushonga.jpg').default
        },
        {
            name : "Amos Manyanye",
            image : require('../asserts/images/arty-events-9561.jpg').default
        },
        {
            name : "Calvin Mapfumo",
            image : require('../asserts/images/arty-events-9522.jpg').default
        },
    ]

    return (
        <div className="OurTeamBody">
            <div className="container">
                <p className="text-center my-5">Afrosoft continues to thrive because of the passion and dedication of its leadership.</p>
                <h1 className="text-center">The Team</h1>
                
                    <div className="row">
                    {
                        team.map((person) => {
                            return (
                                <div className="col-md-3 team-image-container">
                                <img src={person.image} alt="Afrosoft Team" className="image" />
                                <div className="overlay">
                                    <div className="text">{person.name}</div>
                                </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default OurTeamBody

