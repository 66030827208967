import React from 'react'
import { Link } from 'react-router-dom'
// import {msgImg} from '../asserts/images/messaging.svg'

function SystemsSection() {
    return (
        <div className="systems-section px-3 ">
            <div className="row">
                 
            <div className="d-none d-md-block col-md-6 left-image"></div>
                <div className="col-md-6 right-text p-5">
                    <h2>Systems</h2>
                    <p>Software solutions just for you!</p>
                    <p>
                    Afrosoft has developed and implemented software solutions for various organizations across Africa.
                    </p>
                    <Link className="cta mt-3" to="/our-work">
                        See our work
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default SystemsSection
