import React from 'react'

function AboutBanner() {
    return (
        <div className="AboutBanner">
            <h1>About Us</h1>
        </div>
    )
}

export default AboutBanner
