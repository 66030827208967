import React from 'react'
import {Link} from "react-router-dom"

const BottomNav = () => {
    return (
        <div className="BottomNav p-2 ">
                <Link to="/">Software Development</Link>
            <Link to="/">Payment Solution</Link>
            <Link to="/">Messaging</Link>
            <Link to="/">Business Intelligence</Link>
            <Link to="/">Resource Outsourcing</Link>
            
        </div>
    )
}


export default BottomNav
