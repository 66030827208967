import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
// import Carousel from "react-elastic-carousel";
import Carousel from "react-simply-carousel";
//import Item from "./Item"
function AwardsSection() {
    const [activeSlide, setActiveSlide] = useState(1);
    const [mobileView, setMobileView] = useState(false);
    //screen size 
    const [notMobileView, setNotMobileView] = useState(false)
    const data = [
        {
            title: "MOST TRUSTED BRAND ICT",
            awardingPlatform: "CHARTERED INSTITUTE OF CUSTOMER RELATIONSHIP MANAGEMENT",
            year: 2018
        },
        {
            title: "TOP ICT INNOVATIVE COMPANY IN AFRICA",
            awardingPlatform: "CHARTERED INSTITUTE OF CUSTOMER RELATIONSHIP MANAGEMENT",
            year: 2015
        },
        {
            title: "KMFS CEO ACHIEVERS AWARD ZIMBABWE",
            awardingPlatform: "NATIONAL ICT ACHIEVEMENTS MINISTRY OF ICT ZIMBABWE",
            year: 2010
        },
        {
            title: "RECOGNITION OF CONTINENTAL EXCELLENCE",
            awardingPlatform: "CHARTERED INSTITUTE OF CUSTOMER RELATIONSHIP MANAGEMENT",
            year: 2017
        },
        {
            title: "CONSUMER’S CHOICE AWARDS",
            awardingPlatform: "NATIONAL ICT ACHIEVEMENTS MINISTRY OF ICT ZIMBABWE",
            year: 2012
        },
        {
            title: "TOP LOCAL BRANDS IN ICT",
            awardingPlatform: "CHARTERED INSTITUTE OF CUSTOMER RELATIONSHIP MANAGEMENT",
            year: 2018
        },
        {
            title: "MOST FOCUSED ORGANISATION",
            awardingPlatform: "CONTACT CENTRE ASSOCIATION IN ZIMBABWE",
            year: 2014
        },
        
    ]
    
    //screen size 
    const handleResize = () => {
        if (window.innerWidth >= 720) {
            setNotMobileView(true)
        } else {
            setNotMobileView(false)
        }
    }

    // event listener
    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize)
    })
    return (
        <div className="accolades-section d-flex flex-column justify-content-center align-items-center">
            <h1 className="mt-5">Accolades</h1>
            <h4 className="text-center my-5 summary">Since 1999 Afrosoft has developed relationships, systems and skills capacity resulting in a national ICT brand that has won many  awards from the Zimbabwean Government,Private sector and Foreign Institutions</h4>
            <div>
                
                <Carousel
                    updateOnItemClick
                    dotsNavWrapperProps={""}
                    containerProps={{
                        style: {
                            width: "100%",
                            justifyContent: "space-between",
                            background: "#fff",
                            borderRadius: "35px"
                        }
                    }}
                    activeSlideIndex={activeSlide}
                    activeSlideProps={{
                        style: {

                        }
                    }}
                    onRequestChange={setActiveSlide}
                    forwardBtnProps={{
                        children: ">",
                        style: {
                            width: 30,
                            height: 60,
                            alignSelf: "center"
                        }
                    }}
                    backwardBtnProps={{
                        children: "<",
                        style: {
                            width: 30,
                            height: 60,
                            alignSelf: "center"
                        }
                    }}
                    itemsToShow={notMobileView ? 1 : 3}
                    speed={400}
                >
                    {data.map((item, index) => (
                        <div className="accolades-tile-background"
                            style={{
                                width: 330,
                                height: 273,
                                border: "20px solid white",
                                textAlign: "center",
                                boxSizing: "content-box",

                            }}
                        >
                            <div className="p text-center title">
                                <p >{item.title}</p>
                            </div>
                            <div className="p text-center awarders">
                                <p >{item.awardingPlatform}</p>
                            </div>
                            <div className="p text-center year">
                                <p >{item.year}</p>
                            </div>



                        </div>
                    ))}
                </Carousel>
            </div>


        </div>
    )
}

export default AwardsSection
