import Home from "./pages/Home";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Navbar from "./components/Navbar";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import Blog from "./pages/Blog";
import OurWork from "./pages/OurWork";
import OutTeam from "./pages/OutTeam";
import PageNotFound from "./pages/PageNotFound";
import Footer from "./components/Footer";
import CareersPage from "./pages/Careers";
import JobDetails from "./components/JobDetails";
import BulkSms from "./components/BulkSms";
import InstalipaPage from "./pages/Instalipa";
import ReactGA from 'react-ga';
import QualityPolicy from "./pages/QualityPolicy";

const TRACKING_ID = "3877867547"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
function App() {

  return (
    <div className="App">
      <Router>
        
        <Navbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={ContactUs} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/our-work" component={OurWork} />
          <Route exact path="/our-team" component={OutTeam} />
          <Route exact path="/careers" component={CareersPage} />
          <Route exact path="/details" component={JobDetails} />
          <Route exact path="/bulk-sms" component={BulkSms} />
          <Route exact path="/quality-policy" component={QualityPolicy} />
          <Route path="/ems"  component={() => (window.location.href = 'https://www.enterprisemessaging.co.zw/')} />
          <Route path="/instalipa" component={InstalipaPage} />
          <Route path='/messaging-app' component={() => {
            window.location.href = 'https://play.google.com/store/apps/details?id=zw.co.afrosoft.instapayapp';
            return null;
          }} />
          {/* <Route path='/messaging-information' component={() => {
            window.location.href = 'https://drive.google.com/file/d/1bk5Ne9SDeN9R888tKbZWzG3CuQOx3fV8/view?usp=sharing';
            return null;
          }} /> */}
          <Route path='/register-as-biller' component={() => {
            window.location.href = 'https://instapayweb.vas.co.zw/Account/Register.aspx';
            return null;
          }} />
          <Route path='/messaging-rate-card' component={() => {
            window.location.href = 'https://drive.google.com/file/d/1-vnjQNbwdx32RoSJJtzlCze-UA7ns1Ud/view?usp=sharing';
            return null;
          }} />
          <Route path='/agromall' component={() => {
            window.location.href = 'https://agromall.vas.co.zw/';
            return null;
          }} />
          <Route path='/instapay' component={() => {
            window.location.href = 'https://instapayweb.vas.co.zw';
            return null;
          }} />
          <Route path='/facebook' component={() => {
            window.location.href = 'https://www.facebook.com/afrosoftzimbabwe';
            return null;
          }} />
          <Route path='/twitter' component={() => {
            window.location.href = 'https://twitter.com/afrosoftH';
            return null;
          }} />
          <Route path='/linkedin' component={() => {
            window.location.href = 'https://www.linkedin.com/company/1379582/';
            return null;
          }} /> <Route path='/instagram' component={() => {
            window.location.href = 'https://instapayweb.vas.co.zw/Account/Register.aspx';
            return null;
          }} />
          <Route path='/youtube' component={() => {
            window.location.href = 'https://www.youtube.com/channel/UCjDMqDPyzFkk6Nb03eSebIw';
            return null;
          }} />

          <Route component={PageNotFound} />
        </Switch >
        <Footer />
      </Router>
    </div>
  );
}

export default App;
