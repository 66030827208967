import React from 'react'
import { Link } from 'react-router-dom'

function MessagingSection () {
    return (
        <div id="messaging" className="messaging px-3">
            <div className="row">
                <div className="d-none d-md-block col-md-6 left-image"></div>
                <div className="col-md-6 right-text p-5">
                    <h2>Bulk SMS Services</h2>
                    <p>
                        We get your message out to everyone! <br/> Our cost-effective system is remarkably secure and <br/> has a multi-access online portal to manage your<br/> messaging
                    </p>
                    
                    {/* <Link className="cta mt-3" to="/messaging-information">
                        Learn More
                    </Link> */}
                    <Link className="cta mt-3" to="/ems">
                        Visit Site
                    </Link>
                </div>
            </div>
            
        </div>
    )
}

export default MessagingSection