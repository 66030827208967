import React from 'react'
import ContactSendButton from './ContactSendButton'
import emailjs from 'emailjs-com';
import apiKeys from '../utils/emailkeys';
import { useState } from 'react';
function InstalipaBody() {
    const [openService, setOpenService] = useState(false)

    const handleOpenService = () => {
        setOpenService(!openService);
    };
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevents default refresh by the browser
        emailjs.sendForm(`service_rgnzk6s`, apiKeys.TEMPLATE_ID, e.target, apiKeys.USER_ID)
            .then((result) => { alert("Message Sent, We will get back to you shortly", result.text); },
                (error) => { alert("An error occurred, Please try again", error.text); }).then(
                    clearForm()
                )
    };
    const clearForm = () => {
        document.getElementById("contact-us-form").reset();
    }
    return (
        <div className="contact-body py-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-6" p-2>
                        <h1 className="text-center">Make a purchase</h1>
                        <div>
                            <h2>How to purchase</h2>
                            <p>1. Select the sevice</p><br />
                            <p>2. Select the mobile payment method</p><br />
                            <p>3. Enter mobile number to pay with</p><br />

                        </div>

                    </div>
                    <div className="col-md-6 p-2">
                        <h2 className="text-center">Enter Details</h2>
                        <form id='contact-us-form' onSubmit={e => { handleSubmit(e) }} class="row g-3">
                            <div class="col-12">
                                <button className="form-control" onClick={handleOpenService}>Select Service</button>
                                {openService ? <ul className="dropdownList">
                                    <li className="dropdownList">
                                        <button className="form-control">Stron Water Meter</button>
                                    </li>
                                    <li className="menu-item">
                                        <button>Other Meter</button>
                                    </li>
                                </ul> : <div>Please select a service</div>}
                            </div>
                            <div class="col-12">
                                <textarea name="name" id="name" placeholder="Name" className="form-control" rows="1"></textarea>
                            </div>
                            <div class="col-12">
                                <textarea name="surname" id="surname" placeholder="Surname" className="form-control" rows="1"></textarea>
                            </div>
                            <div class="col-12">
                                <textarea name="email" id="email" placeholder="Email Address" className="form-control" rows="1"></textarea>
                            </div>

                            <div class="col-12">
                                <textarea name="message" id="message" className="form-control" rows="4"></textarea>
                            </div>
                            <div class="col-12">
                                <button type="submit" class="btn contact-send-button btn-primary message-button round-corners ">Send</button>
                            </div>


                            {/*<div class="col-12">
                                <div type="submit" class="btn contact-send-button btn-primary message-button round-corners ">
                                <ContactSendButton label="Send" mailto="mailto: info@afrosoft.co.zw" />
                                </div>
                                </div>
                                */}

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InstalipaBody
