import React from 'react'
import OurWorkBanner from '../components/OurWorkBanner'
import OurWorkBody from '../components/OurWorkBody'

function OurWork() {
    return (
        <div>
            <OurWorkBanner />
            <OurWorkBody />
        </div>
    )
}

export default OurWork
