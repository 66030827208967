import React from 'react'

function InstalipaBanner() {
    return (
        <div className="AboutBanner">
            <h1>Welcome to InstaLipa</h1>
        </div>
    )
}

export default InstalipaBanner
