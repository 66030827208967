import React from 'react'

function OurTeamBanner() {
    return (
        <div className="OurTeamBanner">
            <h1>Our Team</h1>
        </div>
    )
}

export default OurTeamBanner
