import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';

const Search = (props) => {
    const [state, setState] = useState({
        description: '',
        location: '',
        full_time: false,
        contract: false,
        front_end: false,
        back_end: false
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'full_time') {
            setState((prevState) => ({ ...state, [name]: !prevState.full_time }));
        } 
        else if (name === 'contract') {
            setState((prevState) => ({ ...state, [name]: !prevState.contract }));
        } 
        else if (name === 'front_end') {
            setState((prevState) => ({ ...state, [name]: !prevState.front_end }));
        }
        else if (name === 'back_end') {
            setState((prevState) => ({ ...state, [name]: !prevState.back_end }));
        }

        else {
            setState({ ...state, [name]: value });
        }
    };

    const handleSearch = (event) => {
        event.preventDefault();
        console.log("Search Results", state);
        props.onSearch(state);
    };

    return (

        <div className="container">
            <h2 className="mt-5 mb-5">Work with us</h2>
            <p >
                Join our ever expanding team of professionals
                by applying for the post that suits
                your career, dreams and qualifications. </p>
            <p></p>
            <div className="search-section">
                <Form className="search-form" onSubmit={handleSearch}>
                    <Row>
                        <Col>
                            <Form.Group controlId="description">
                                <Form.Control
                                    type="text"
                                    name="description"
                                    value={state.description || ''}
                                    placeholder="Enter search term"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="location">
                                <Form.Control
                                    type="text"
                                    name="location"
                                    value={state.location || ''}
                                    placeholder="Enter a keyword"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Button variant="primary" type="submit" className="btn-search">
                                Search
                            </Button>
                        </Col>
                    </Row>
                    <div className="filters">
                        
                        <Form.Group controlId="full_time">
                        <div class="mx-auto"></div>
          <ul class="navbar-nav">
            <li class="nav-item horizontal">
            <Form.Check
                                type="checkbox"
                                name="full_time"
                                className="full-time-checkbox"
                                label="Full time only"
                                checked={state.full_time}
                                onChange={handleInputChange}
                            />
            </li>
            <li class="nav-item ">
            <Form.Check
                                type="checkbox"
                                name="contract"
                                label="Contract"
                                checked={state.contract}
                                onChange={handleInputChange}
                            />
            </li>
            <li class="nav-item">
            <Form.Check
                                type="checkbox"
                                name="front_end"
                                label="Front-end"
                                checked={state.front_end}
                                onChange={handleInputChange}
                            />
            </li>
            <li class="nav-item">
            <Form.Check
                                type="checkbox"
                                name="back_end"
                                label="Back-end"
                                checked={state.back_end}
                                onChange={handleInputChange}
                            />
            </li>
          </ul>
                        </Form.Group>
                    </div>
                </Form>
            </div>

        </div>
    );
};
export default Search;
